import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { axiosInstance } from "../../services/axios";
import { Row, Col, Button } from "react-bootstrap";
import SideBar from "../SideBar";
const UserView = ({ id }) => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const getUser = () => {
    axiosInstance.get(`/api/user/${id}`).then((res) => {
      setUser(res.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    getUser();
  }, []);
  console.log("set users", user);
  return (
    <Row>
      <Col xs="12" sm="2">
        <SideBar active="/app/user/manage" />
      </Col>
      <Col xs="12" sm="10">
        <h1>
          User Account{" "}
          <Link to={`/app/user/update/${id}`}>
            <Button variant="info" className="ml-1">
              Edit
            </Button>
          </Link>
        </h1>
        <div style={{ borderBottom: "solid white 2px" }} className="pt-3 mb-5"></div>
        <Row>
          <Col xs="3" xl="2">
            <p>Name: </p>
            <p>Company:</p>
            <p>E-mail:</p>
            <p>Subscription:</p>
            <p>Admin:</p>
          </Col>
          <Col>
            <p>{user.name}</p>
            <p>{user.companyName}</p>
            <p>{user.email}</p>
            <p>{user.subscription}</p>
            <p>{user.admin ? "Yes" : "No"}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default UserView;
