import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/Layout";
import Profile from "../components/app/Profile";
import PrivateRoute from "../components/PrivateRoute";
import Dashboard from "../components/app/Dashboard";
import UserManagement from "../components/app/UserManagement";
import UserView from "../components/app/UserView";
import UserAddUpdate from "../components/app/UserAddUpdate";
import TrainingVideos from "./TrainingVideos";

const App = () => (
  <Layout>
    <Router>
      {/*
        Minimum level can be "Basic", "Premium", or "admin"
      */}
      <PrivateRoute path="/app/users/manage" component={UserManagement} minimumLevel="admin" />
      <PrivateRoute path="/app/user/:id" component={UserView} minimumLevel="admin" />
      <PrivateRoute path="/app/user/update/:id" component={UserAddUpdate} minimumLevel="admin" />
      <PrivateRoute path="/app/profile" component={Profile} />
      <PrivateRoute path="/app/dashboard" component={Dashboard} />
      <PrivateRoute path="/app/training-videos" component={TrainingVideos} minimumLevel="Premium" />
    </Router>
  </Layout>
);
export default App;
