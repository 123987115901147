import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { axiosInstance } from "../../services/axios";
import { Row, Col, Table } from "react-bootstrap";
import SideBar from "../SideBar";
const UserManagement = ({ id }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const getUsers = () => {
    axiosInstance.get("/api/users").then((res) => {
      setUsers(res.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <Row>
      <Col xs="12" sm="2">
        <SideBar active="/app/users/manage" />
      </Col>
      <Col xs="12" sm="10">
        <h1>User Management</h1>
        <div style={{ borderBottom: "solid white 2px" }} className="pt-3 mb-5"></div>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Email</th>
              <th>Company</th>
              <th>Subscription Level</th>
              <th>Admin</th>
            </tr>
          </thead>
          {!loading ? (
            <tbody>
              {users.map((user, i) => (
                <tr key={user._id} onClick={() => navigate(`/app/user/${user._id}`)}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.companyName}</td>
                  <td>{user.subscription}</td>
                  <td>{user.admin ? "Yes" : "No"}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody></tbody>
          )}
        </Table>
      </Col>
    </Row>
  );
};
export default UserManagement;
