import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col, Alert, ButtonGroup } from "react-bootstrap";
import { axiosInstance } from "../../services/axios";
import { Link, navigate } from "gatsby";
import { handleSignup } from "../../services/auth";
import Layout from "../Layout";
import SideBar from "../SideBar";

const UserAddUpdate = ({ id }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [warnDelete, setWarnDelete] = useState(false);
  const [navURL, setNavURL] = useState(-1);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [subscription, setSubscription] = useState("");
  const [email, setEmail] = useState("");
  const [admin, setAdmin] = useState("");
  const [loading, setLoading] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`/api/user/update/${id}`, {
        name,
        companyName,
        subscription,
        admin,
      })
      .then(function ({ data: { status, message } }) {
        if (status === "success") {
          setSuccessMessage(message);
        } else {
          setErrorMessage(message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`/api/user/delete/${id}`)
      .then(function ({ data: { status, message } }) {
        if (status === "success") {
          setNavURL("/app/users/manage");
          setSuccessMessage(message);
        } else {
          setErrorMessage(message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getUser = () => {
    axiosInstance.get(`/api/user/${id}`).then((res) => {
      const { name, companyName, subscription, email, admin } = res.data;
      setName(name);
      setEmail(email);
      setCompanyName(companyName);
      setSubscription(subscription);
      setAdmin(admin);
      setLoading(false);
    });
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <Layout>
      <Row>
        <Col xs="12" sm="2">
          <SideBar active="/app/users/manage" />
        </Col>
        <Col xs="12" sm="10">
          <Row className="flex-column">
            <Col lg="4">
              <h1>Edit User Account</h1>
              <div style={{ borderBottom: "solid white 2px" }} className="pt-3"></div>
              {!!errorMessage ? <Alert variant="warning">{errorMessage}</Alert> : ""}
            </Col>
            <Col lg="4" className="mt-3">
              <Form onSubmit={(e) => handleSubmit(e)}>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicCompanyName">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter company name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail" disabled>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={email} disabled />
                  <Form.Text id="passwordHelpBlock" muted>
                    Email cannot be edited, it is the account identifier.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Subscription Level</Form.Label>

                  <Form.Control
                    as="select"
                    type="text"
                    placeholder="Basic"
                    value={subscription}
                    defaultValue={subscription}
                    onChange={(e) => setSubscription(e.target.value)}
                  >
                    <option>None</option>
                    <option>Basic</option>
                    <option>Premium</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Admin</Form.Label>
                  <Form.Control
                    as="select"
                    type="text"
                    value={admin}
                    defaultValue={admin}
                    onChange={(e) => setAdmin(e.target.value)}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </Form.Control>
                </Form.Group>
                {!!successMessage ? <Alert variant="success">{successMessage}</Alert> : ""}
                {!successMessage ? (
                  <>
                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading ? "Loading…" : "Submit"}
                    </Button>
                    <Button variant="secondary" className="ml-2" onClick={() => navigate(-1)} disabled={loading}>
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      className="float-right"
                      onClick={() => setWarnDelete(true)}
                      disabled={warnDelete}
                    >
                      Delete User
                    </Button>
                    <br />
                    {!!warnDelete ? (
                      <div className="float-right mt-1 d-flex flex-column justify-content-end">
                        <Form.Text muted>Are you sure? This action cannot be undone.</Form.Text>
                        <ButtonGroup>
                          <Button variant="danger" onClick={(e) => handleDelete(e)}>
                            Delete
                          </Button>
                          <Button variant="secondary" className="ml-2" onClick={() => setWarnDelete(false)}>
                            Cancel
                          </Button>
                        </ButtonGroup>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <Button onClick={() => navigate(navURL)}>Go Back</Button>
                )}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default UserAddUpdate;
