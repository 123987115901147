import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import { Button, Row, Col } from "react-bootstrap";
import StoreContext from "../context/StoreContext";
import SideBar from "../SideBar";
const Dashboard = () => {
  const {
    store: { user },
    updateUser,
  } = useContext(StoreContext);

  useEffect(() => {
    updateUser();
  }, []);
  return (
    <Row>
      <Col xs="12" sm="2">
        <SideBar active="/app/dashboard" />
      </Col>
      <Col xs="12" sm="10">
        <h1>Dashboard</h1>
        <div style={{ borderBottom: "solid white 2px" }} className="pt-3 mb-5"></div>
        <Row>
          <Col xs="3" xl="2">
            <p>Name: </p>
            <p>Company:</p>
            <p>E-mail:</p>
            <p>Subscription:</p>
            <p>Admin:</p>
          </Col>
          <Col>
            <p>{user.name}</p>
            <p>{user.companyName}</p>
            <p>{user.email}</p>
            <p>{user.subscription}</p>
            <p>{user.admin ? "Yes" : "No"}</p>
          </Col>
        </Row>

        {user.subscription === "Premium" ? (
          <p className="mt-5">
            As a Premium subscriber you have access to our{" "}
            <Link to="/app/training-videos">
              <Button variant="dark">Training Videos</Button>
            </Link>
            .
          </p>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};
export default Dashboard;
